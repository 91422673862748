<template>
  <div class="container">
    <b-card title="Keywords">
      <!-- Use v-if to conditionally render the chart only when data is available -->
      <div v-if="dataLoaded">
        <apexchart height="350" :options="chartOptions" :series="series"></apexchart>
      </div>
      <div v-else class="d-flex align-items-center justify-content-center">
        <b-spinner type="grow" variant="success" label="Loading..."></b-spinner>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard,BSpinner } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import axios from "@axios";
export default {
  components: {
    BCard,
    BSpinner,
    apexchart: VueApexCharts
  },
  data() {
    return {
      series: [],
      chartOptions: {
        legend: {
          show: false
        },
        chart: {
          height: 350,
          type: 'treemap'
        },
      },
      dataLoaded: false,
    }
  },
  mounted() {
    this.getSearchWords();
  },
  methods: {
    getSearchWords() {
      axios
        .get("/get-event-count-words")
        .then(({ data }) => {
          this.series.push(data);
          this.dataLoaded = true;
        })
        .catch((error) => {
          console.error(error);
          this.dataLoaded = true;
        });
    }
  }
}
</script>
